(function (ng, user_id) {
    'use strict';

    ng.module('disima',
        ['ngSanitize', 'blockUI','thatisuday.dropzone', 'ui.select', 'ngDesktopNotification']).config(
            ['blockUIConfig', '$interpolateProvider', '$locationProvider', 'uiSelectConfig', function(blockUIConfig, $interpolateProvider, $locationProvider, uiSelectConfig) {

        blockUIConfig.autoInjectBodyBlock = true;
        blockUIConfig.blockBrowserNavigation = true;
        blockUIConfig.autoBlock = true;

        // Change the default overlay message
        blockUIConfig.message = 'Loading ...';

        // Change the default delay to 100ms before the blocking is visible
        blockUIConfig.delay = 100;

        uiSelectConfig.theme = 'bootstrap';
        uiSelectConfig.resetSearchInput = true;
        uiSelectConfig.appendToBody = false;

        $interpolateProvider.startSymbol('//').endSymbol('//');

        blockUIConfig.requestFilter = function(config) {
            if(config.url.match(/background_request/)) {
                return false; // ... don't block it.
            }
        };
    }]);
}(angular));
