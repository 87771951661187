(function (ng) {
    'use strict';
    var app = ng.module('disima');

    app.directive('dlEnterKey', function() {
        return function(scope, element, attrs) {
            element.bind("keydown keypress", function(event) {

                var keyCode = event.which || event.keyCode;

                // If enter key is pressed
                if (keyCode === 13) {
                    scope.$apply(function() {
                        // Evaluate the expression
                        scope.$eval(attrs.dlEnterKey);
                    });

                    event.preventDefault();
                }
            });
        };
    });

    app.directive('datepicker', function() {
        return {
            require: 'ngModel',
            link: function(scope, el, attr, ngModel) {

                $(el).daterangepicker({
                    "singleDatePicker": true,
                    "timePicker": true,
                    "showDropdowns": true,
                    "timePicker24Hour": true,
                    "drops": "down",
                    "autoUpdateInput": false,
                    "locale": daterangepickerLocale
                }, function(start, end, label) {
                    $(this.element).val(start.format(daterangepickerLocale.format));
                    ngModel.$setViewValue(start.format(daterangepickerLocale.format));
                });
            }
        };
    });

}(angular));